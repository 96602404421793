import { title } from "assets/jss/material-kit-react.jsx";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  gridContainer: {
    marginTop: "2em",
  },
  cardTitle: {
    ...title,
    marginBottom: ".5rem",
    marginTop: "1rem",
    minHeight: "32px",
    textDecoration: "none",
    color: "white",
  },
  cardSubtitleHeader: {
    fontSize: "1.313rem",
    //margin: "1em auto 0",
    fontWeight: "bolder",
  },
  cardSubtitle: {
    fontSize: "1.313rem",
    //margin: "1em auto 0"
  },
  card: {
    minHeight: "30em",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
    backgroundColor: "#009688",
  },
  link: {
    //backgroundColor: "#009688",
    outlineColor: "#009688",
    color:"black",
    '&:hover': {
      color: "#0000ee"
    },
    borderRadius:"6px",
    fontWeight: "bolder",
    padding:"10px 15px",
    width: "100%", 
    textAlign: "center",
    //margin: "10px",
  },
  description: {
    color: "#999"
  }
};


export default productStyle;