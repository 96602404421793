import React from "react";
import { Link } from 'gatsby';

import withStyles from "@material-ui/core/styles/withStyles";


import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PaymentIcon from "@material-ui/icons/Payment";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Try Book Merlin for seven days. We're convinced that you will join us after!</h2>
            <h5 className={classes.description}>
              We cannot wait to have you onboard! So smash that link and join us!
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer className={classes.gridContainer}>
           {/* <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Free Trial"
                description="Start your free trial today!"
                icon={MoneyOffIcon}
                iconColor="info"
                vertical
              /> 
            </GridItem> 
          <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Monthly $9.99"
                description="Join now!"
                icon={MonetizationOnIcon}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Yearly $100"
                description="Join now and save 16%!"
                icon={PaymentIcon}
                iconColor="info"
                vertical
              />
          </GridItem> */}
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes.card}>
              <form >
                <CardHeader className={classes.cardHeader}>
                  <h4 className={classes.cardTitle}>Free Trial</h4>
                      
                </CardHeader>
                <CardBody >
                  <h2 className={classes.cardSubtitleHeader}>
                    Try BookMerlin on us!
                  </h2>
                  <h4 className={classes.description}>
                    With just the press of a button you will have access to the wonders of BookMerlin!
                  </h4>
                </CardBody>
                <CardFooter >
                  <Link className={classes.link}>Start your free trial today!</Link>
                </CardFooter>
              </form>
            </Card>  
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes.card}>
              <form >
                <CardHeader className={classes.cardHeader}>
                  <h4 className={classes.cardTitle}>Monthly</h4>
                      
                </CardHeader>
                <CardBody >
                  <h2 className={classes.cardSubtitleHeader}>
                    Our most popular option! 
                  </h2>
                  <h4 className={classes.description}>
                    For just $9.99 a month, you get access to BookMerlin where you can write, edit, format, and generate in just one app!
                  </h4>  
                </CardBody>
                <CardFooter >
                  <Link className={classes.link}>Start today!</Link>   
                </CardFooter>
              </form>
            </Card>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes.card} >
              <form >
                <CardHeader className={classes.cardHeader}>
                  <h4 className={classes.cardTitle}>Yearly</h4>
                      
                </CardHeader>
                <CardBody >
                  <h2 className={classes.cardSubtitleHeader}>
                    Join now and save 16%! 
                  </h2>  
                  <h4 className={classes.description}>
                    It may seem like a lot up front but in the end you'll save some cash!
                  </h4>  
                </CardBody>
                <CardFooter >
                  <Link className={classes.link}>Start today!</Link>    
                </CardFooter>
              </form>
            </Card>
          </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
